<template>
  <div class="passedSuccess-box u-text-center">
    <div class="passedSuccess-item">
      <img
        class="icon-right"
        src="../assets/images/active_limit_defaule_jump_check.png"
      />
      <h3 class="passedSuccess-title">您的贷款已提交</h3>
      <span class="passedSuccess-span"> 您的贷款已提交，请等待审核 </span>
    </div>

    <!-- 提示浏览器打开遮罩层 -->
    <SharePop :show="showPop" @change="handlePopChange" />
  </div>
</template>

<script>
export default {
  name: "Download",
  data() {
    return {
      channel: this.$route.query.source, // 渠道标识
      brand: this.$route.query.brand, // 手机型号标识
      downAppUrl: undefined, // 下载地址
      showPop: false, // 分享蒙层状态
    };
  },

  methods: {
    // 监听提示浏览器打开弹层（我知道了）按钮点击
    handlePopChange(show) {
      this.showPop = show;
    },
  },
};
</script>

<style lang="scss" scoped>
.passedSuccess-box {
  height: 100vh;
  padding-bottom: 0.88rem;
  font-size: 0.24rem;

  > div.passedSuccess-item {
    background: #fff;
    padding: 0.62rem 0.4rem 0.36rem;
    margin-bottom: 0.24rem;
  }

  .icon-right {
    width: 0.94rem;
    height: 0.94rem;
    margin: 0 auto 0.22rem;
  }

  .passedSuccess-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 0.34rem;
    color: #333333;
    line-height: 0.49rem;
  }

  .passedSuccess-span {
    display: block;
    margin: 0.18rem 0 0.48rem;

    font-size: 0.26rem;
    color: #8c8c8c;
    line-height: 0.37rem;
  }

  .passedSuccess-item {
    padding: 0.4rem 0.3rem 0.3rem;
    background: #f6f8ff;
    border-radius: 0.4rem;

    .icon-logo {
      width: 1.32rem;
      height: 1.32rem;
      margin-right: 0.24rem;
    }

    .icon-logo-mini {
      width: 0.84rem;
      height: 0.84rem;
      margin-right: 0.24rem;
    }

    .carLoanSuccess-tit {
      font-size: 0.32rem;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      color: #111c31;
    }

    .tips {
      color: #868e9e;
      margin: 0.04rem 0 0.08rem;

      span {
        color: #f03d23;
      }
    }

    .van-tag {
      margin-right: 0.12rem;
      font-size: 0.22rem;
    }

    .borr-parameters {
      margin: 0.3rem 0 0.48rem;
      padding-top: 0.3rem;
      border-top: 0.02rem solid #e6e7ee;

      .countdown {
        span {
          text-decoration: line-through;
          margin-right: 0.04rem;
          color: #c3c2c6;
        }
      }

      .borr-parameters-ul {
        margin: 0.04rem 0 0.44rem;

        p {
          font-size: 0.4rem;
          font-family: AlibabaPuHuiTiR, AlibabaPuHuiTiR-Regular;
          color: #111c31;
          margin-bottom: 0.04rem;
        }

        small {
          color: #868e9e;
        }
      }

      .originate-loan {
        color: #868e9e;

        p:first-child {
          > span {
            color: #f03d23;
          }
        }

        p:last-child {
          background: #ffede9;
          font-size: 0.22rem;
          color: #eb503b;
          padding: 0 0.14rem;
          line-height: 0.4rem;
          border-radius: 0.3rem;
        }
      }

      .step {
        margin-top: 0.24rem;
        height: 0.18rem;
        background: #ffede9;
        border-radius: 0.12rem;
      }

      .step-line {
        min-width: 1%;
        width: 1%;
        height: 100%;
        border-radius: 0.6rem;
        animation: warning-animation 2s infinite linear;
        background: linear-gradient(
          -45deg,
          #ffede9 25%,
          #f74c0b 0,
          #f4270c 50%,
          #f4a587 0,
          #ffffff 75%,
          #f03016 0
        );
        background-size: 0.16rem 0.16rem;
      }

      .step-line-move {
        width: 90%;
        transition: width 2s linear;
      }

      .step-line:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        border-radius: 0.24rem;
        background-image: linear-gradient(
          to bottom,
          #fddca4,
          rgba(243, 243, 229, 60%) 15%,
          transparent 60%,
          #f3f9e8
        );
      }

      @keyframes warning-animation {
        0% {
          background-position: 0 0;
        }
        100% {
          background-position: 1rem 0;
        }
      }
    }
  }
}
</style>
